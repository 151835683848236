body {
  font-size: 20px;
  font-family: 'Red Hat Mono', monospace;
}

.container {
  position: absolute;
  width: 261px;
  height: 450px;
  /*left: 67px;
  top: 304px;*/

  background: rgba(255, 255, 255, 0.7);
  border-radius: 50px;

  top: 60%;
  left: 50%;
  text-align: center;
  margin: 0;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;

  -webkit-transform: translate(-50%, -60%);
  transform: translate(-50%, -60%);
}

.switch-container {
  align-items: flex-start;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
}

strong {
  text-align: justify;
  display: inline-block;
  white-space: nowrap;
}

hr.rounded {
  border-top: 4px solid #18c1f7;
  border-radius: 2px;
}
